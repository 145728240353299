import './ApplyList.scss';

import React from 'react';
import { applyImageUrl1 } from '../../../../images';

export function ApplyList() {
  return <div className="apply-list">
    <div className="apply-list__header">我们的客户</div>
    <div className="apply-list__content">
      <div className="apply-list__content__row">
        <div className="apply-list__content__row__item">
          <img src="https://www.meta-pointer.com/logo_kt_h120.jpg" alt="" />
        </div>
        <div className="apply-list__content__row__item">
          <img src="https://boss.jiahaoxuan.com/assets/logo.png" alt="" />
        </div>
        <div className="apply-list__content__row__item">
          <img src="https://www.meta-pointer.com/logo_no9_120x120.jpg" alt="" />
        </div>
        <div className="apply-list__content__row__item">
          <img src="https://www.meta-pointer.com/logo_gsd_h120.png" alt="" />
        </div>
      </div>
    </div>
  </div>;
}