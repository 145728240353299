import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { Home, About } from './routes';
import { Header, Footer } from './components';

function App() {
  return (
    <div className='app'>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
