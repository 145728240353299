import './ServicePromise.scss';

import React from 'react';
import { LearnMoreButton } from '../../../../components';

export function ServicePromise() {
  return <div className="service-promise">
    <div className="title">选择我们，选择放心</div>
    <div className="description">专业、优质服务值得您信赖</div>
    <LearnMoreButton type="primary" className="button-more">了解更多</LearnMoreButton>
  </div>;
}