import './Header.scss';

import React from 'react';
import { Navigation } from './Navigation';
import { compassImageUrl } from '../../images';

export function Header() {
  return <div className="header">
    <div className="header--inner">
      <img className="logo" src="http://www.meta-pointer.com/logo2_now2_108.png" alt="" />
      <div className="title">元指针科技</div>
    </div>
  </div>;
}
