import './Banner.scss';

import React from 'react';
import { homeBannerUrl } from '../../../../images';
import { LearnMoreButton } from '../../../../components';

export function Banner() {
  return <div className="banner">
    <div className="banner__background" style={{backgroundImage: `url(${homeBannerUrl})`}}></div>
    <div className="banner__content">
      <img className="banner__content_image" alt="" src={homeBannerUrl} />
      <div className="banner__content_detail">
        <div className="banner__content_detail_card">
          <div className="title">全行业全场景的私域经营解决方案提供商</div>
          <div className="description">
            <div>✓ 全渠道搭建线上商城 ✓ 精细化经营会员 ✓ 获取社交电商生意增量</div>
          </div>
          <LearnMoreButton className='button-more'>了解更多</LearnMoreButton>
        </div>
      </div>
    </div>
  </div>;
}