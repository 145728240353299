import './ShopWindow.scss';

import React from 'react';
import {
  shopWindowImageUrl1,
  shopWindowImageUrl2,
  shopWindowImageUrl3,
  shopWindowImageUrl4,
} from '../../../../images';
import { LearnMoreButton } from '../../../../components';

export function ShopWindow() {
  return <div className="shop-window">
    <div className="shop-window__item">
      <div className="introduction">
        <div className="title">推广获客: 客户在哪里，店就开到哪里</div>
        <div className="sub-title"></div>
        <div className="description">
          <div>打通微信、抖音、小红书、视频号等10+推广渠道，获得海量曝光</div>
        </div>
        <LearnMoreButton className="button-more" />
      </div>
      <img src="https://www.meta-pointer.com/1.png" alt="" />
    </div>
    <div className="shop-window__item">
      <img src="https://www.meta-pointer.com/2.png" alt="" />
      <div className="introduction margin">
        <div className="title">成交转化: 百余种营销玩法，花式卖货促成交</div>
        <div className="sub-title"></div>
        <div className="description">
          <div>追踪营销新趋势，跟进热门玩法，把握客户跟你的每一次接触</div>
        </div>
        <LearnMoreButton className="button-more" />
      </div>
    </div>
    <div className="shop-window__item">
      <div className="introduction">
        <div className="title">行业方案: 蛋糕烘培</div>
        <div className="sub-title">康泰饼家、嘉豪轩、怪兽豆烘培</div>
        <div className="description">
        </div>
        <LearnMoreButton className="button-more" />
      </div>
      <img src="https://www.meta-pointer.com/3.png" alt="" />
    </div>
    <div className="shop-window__item">
      <img src="https://www.meta-pointer.com/4.png" alt="" />
      <div className="introduction margin">
        <div className="title">行业方案: 茶饮酒水</div>
        <div className="sub-title">No.9 咖啡</div>
        <div className="description">
        </div>
        <LearnMoreButton className="button-more" />
      </div>
    </div>
  </div>;
}