import React from 'react';
import { Banner, ShopWindow, BusinessSupport, ApplyList, ServicePromise } from './components';

export function Home() {
  return <div className="home-page">
		<Banner />
		<ShopWindow />
		<BusinessSupport />
		<ApplyList />
		<ServicePromise />
	</div>;
}