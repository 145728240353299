import './Footer.scss';

import React from 'react';
import { wechatIconUrl, weiboIconUrl, twitterIconUrl } from '../../images';

export function Footer() {
  return <div className="footer">
    <div className="footer--inner">
      <div className="footer--inner-column">
        <div className="contaction">
          <div className="title">深圳市元指针科技有限公司</div>
          <div className="description">
            <div>地址：深圳市南山区南头街道前海社区桃园路283号 港湾丽都裙楼201-E70</div>
            <div>电话：17688717388</div>
            <div>邮箱：pr@meta-pointer.com</div>
          </div>
          <div className="more">
            <div className="logo">
              <img src={weiboIconUrl} alt="" />
            </div>
            <div className="logo">
              <img src={wechatIconUrl} alt="" />
            </div>
            <div className="logo">
              <img src={twitterIconUrl} alt="" />
            </div>
          </div>
          <div className="links">
         元指针科技 © 2023 Meta-Pointer.com. All Rights Reserved <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023100438号</a>
          </div>
        </div>
      </div>
    </div>
  </div>
}