import './BusinessSupport.scss';

import React from 'react';
import {
  businessSupportImageUrl1,
  businessSupportImageUrl2,
  businessSupportImageUrl3,
  businessSupportImageUrl4,
  businessSupportImageUrl5,
  businessSupportImageUrl6,
} from '../../../../images';
import { LearnMoreButton } from '../../../../components';

export function BusinessSupport() {
  return <div className="business-support">
    <div className="business-support__header">这些行业都可以选择我们</div>
    <div className="business-support__content">
      <div className="business-support__content__row">
        <img className="image-top" src={businessSupportImageUrl1} alt="" />
        <img className="image-middle" src={businessSupportImageUrl2} alt="" />
        <img className="image-tail" src={businessSupportImageUrl3} alt="" />
      </div>
      <div className="business-support__content__row">
        <img className="image-top" src={businessSupportImageUrl4} alt="" />
        <img className="image-middle" src={businessSupportImageUrl5} alt="" />
        <img className="image-tail" src={businessSupportImageUrl6} alt="" />
      </div>
    </div>
    <div className="business-support__footer">
      <LearnMoreButton className="button-more">了解更多行业</LearnMoreButton>
    </div>
  </div>;
}